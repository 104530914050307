<template>
  <div class="clinic-intro">
    <BannerKv :titleEn="BannerKvData.titleEn" :titleZh="BannerKvData.titleZh" :backgroundPic="BannerKvData.backgroundPic"
      :backgroundPicRwd="BannerKvData.backgroundPicRwd" :bgcolor="'#fff'" />

    <section class="clinic-intro__content">

      <div class="container w1400">
        <rwd-row :data="clinicData">
          <template v-slot="clinic">

            <div class="item" @click="
              toPage({
                name: 'ClinicIntroDetail',
                params: {
                  uuid: clinic.data.uuid
                }
              })
            ">

              <div class="pic">
                <figure class="pic__cover" :style="{
                  'background-image': `url(${clinic.data.cover})`
                }"></figure>
                <!-- <img src="https://nuwa-new-api.howdesign.com.tw/uploads/articles/2cfe1177a3901b05d8b1a2839ea9f8d1.jpg" /> -->
              </div>
              <div class="text txt-center">
                <div>
                  <h5 class="title txt-bold">
                    <span class="title__text">{{ clinic.data.title }}</span>
                  </h5>

                  <p class="info small">
                    {{ $t("ivfContent") }}

                  </p>
                </div>
              </div>
            </div>
          </template>
        </rwd-row>
      </div>
    </section>

  </div>
</template>

<script>
// import { getLocationPic } from '@/lib/public';
import {
  // mapMutations, mapGetters,
  mapState
} from 'vuex';

import '@/assets/scss/about.scss';
import BannerKv from '@/components/BannerKv.vue';
import RwdRow from '@/components/global/RwdRow.vue';
// import AboutTabs from '@/components/about/AboutTabs.vue';
import pic01 from '@/assets/img/about_nuwa_bn_web.jpg';
import pic02 from '@/assets/img/about_nuwa_bn_mob.jpg';
import imageSource from '@/utils/imageSource';

export default {
  name: 'ClinicIntro',
  components: {
    BannerKv,
    RwdRow,
    // AboutTabs
  },
  data() {
    return {
      BannerKvData: {
        titleZh: this.$t('clinicIntro'),
        titleEn: 'Location Introduction',
        backgroundPic: pic01,
        backgroundPicRwd: pic02
      },
    };
  },
  computed: {
    ...mapState(['lang', 'articles']),
    clinicData() {
      const clinicList = this.articles.filter(({ type }) => type === 'introduction');
      if (clinicList.length === 0) {
        return [];
      }

      return clinicList.map((clinic) => ({
        ...clinic,
        cover: imageSource(clinic.data?.images[0])
      }));
    },

    // for test layout
    clinicDataArr() {
      return [...this.clinicData, ...this.clinicData, ...this.clinicData, ...this.clinicData, ...this.clinicData, ...this.clinicData, ...this.clinicData];
    }
  },
  methods: {
    toPage(routeInfo) {
      this.$router.push(routeInfo);
    }
  },
  watch: {

  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/other/color.scss";

.clinic-intro {
  // background-color: $c-page-bg;
}

.clinic-intro__content {
  padding: 60px 0 80px;

  .container {
    margin-top: 60px;
    display: flex;
    justify-content: space-between;

    .item {
      // width: 32.2%;
      // height: 32.2%;
      height: 350px;
      overflow: hidden;
      border-radius: 10px;
      box-shadow: 0 0 10px rgba(0, 0, 0, .3);
      position: relative;
      cursor: pointer;

      .pic {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;

        &__cover {
          width: 100%;
          height: 100%;
          background-color: #ccc;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
        }
      }

      .text {
        min-height: 100%;
        position: relative;
        padding: 0 20px 20px;
        background-color: #fff;
        transform: translateY(calc(100% - 100px));
        transition: .3s;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .title {
          height: 100px;
          display: flex;
          justify-content: center;
          align-items: center;

        }

        .info {
          // max-width: 280px;
          text-align: center;
          // margin: 20px auto 20px;
        }

        .btn {
          margin: 0 auto;
        }
      }

      // &:hover {
      //   @media screen and (min-width: 1024px) {
      //     .text {
      //       background-color: rgba(white, .9);
      //       transform: translateY(0);
      //     }
      //   }
      // }
    }
  }

  @media screen and (max-width: 1023px) {
    .container {
      flex-wrap: wrap;

      .item {
        width: 100%;
        margin: 10px 0;

        .text {
          background-color: rgba(255, 255, 255, .9);
          transform: translateY(0);
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .container {
      margin-top: 30px;
    }
  }
}
</style>
