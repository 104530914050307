<template>
  <div class="c-box-rwd-transform">
    <slot name="header" />
    <loading v-if="isLoading" />
    <ul v-else class="box-list">
      <li v-for="d of data" :key="d.id" class="box-list__item">
        <div class="box-list__item__box">
          <slot :data="d" />
        </div>
      </li>
    </ul>
</div>
</template>

<script>
export default {
  name: 'NormalBoxRwdTransfrom',
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/other/mixin.scss";
@import "@/assets/scss/other/color.scss";

.box-list {
  @include flex(flex-start, flex-start);
  flex-wrap: wrap;
  margin: 0 -7px;

  &__item {
    flex: none;
    width: 100%;
    margin-top: 46px;
    // height: 350px;

    &:first-child {
      margin-top: 0;
    }

    &__box {
      @include padding(0 10px);
    }
  }
}

@media screen and (min-width: 576px) {
  .box-list {
    @include flex(flex-start, flex-start);
    flex-wrap: wrap;
    margin: 0 -10px;

    &__item {
      flex: none;
      width: 50%;
      margin-top: 46px;
      // height: 350px;

      &:first-child,
      &:nth-child(2) {
        margin-top: 0;
      }

    }
  }
}

@media screen and (min-width: 768px) {
  .box-list {
    &__item {
      width: 33.3333333%;

      &:nth-child(3) {
        margin-top: 0;
      }
    }
  }
}
</style>
