const imageDomainUrl = process.env.VUE_APP_API_NEW;

const imageSource = (src) => {
  if (!src) { return null; }
  if (typeof src === 'string') {
    if (src.includes('http')) {
      return src;
    }

    if (src[0] === '/') {
      return encodeURI(`${imageDomainUrl}${src.slice(1)}`);
    }

    return encodeURI(`${imageDomainUrl}/${src}`);
  }

  return null;
};

export default imageSource;
