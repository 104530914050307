var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"clinic-intro"},[_c('BannerKv',{attrs:{"titleEn":_vm.BannerKvData.titleEn,"titleZh":_vm.BannerKvData.titleZh,"backgroundPic":_vm.BannerKvData.backgroundPic,"backgroundPicRwd":_vm.BannerKvData.backgroundPicRwd,"bgcolor":'#fff'}}),_c('section',{staticClass:"clinic-intro__content"},[_c('div',{staticClass:"container w1400"},[_c('rwd-row',{attrs:{"data":_vm.clinicData},scopedSlots:_vm._u([{key:"default",fn:function(clinic){return [_c('div',{staticClass:"item",on:{"click":function($event){return _vm.toPage({
              name: 'ClinicIntroDetail',
              params: {
                uuid: clinic.data.uuid
              }
            })}}},[_c('div',{staticClass:"pic"},[_c('figure',{staticClass:"pic__cover",style:({
                'background-image': `url(${clinic.data.cover})`
              })})]),_c('div',{staticClass:"text txt-center"},[_c('div',[_c('h5',{staticClass:"title txt-bold"},[_c('span',{staticClass:"title__text"},[_vm._v(_vm._s(clinic.data.title))])]),_c('p',{staticClass:"info small"},[_vm._v(" "+_vm._s(_vm.$t("ivfContent"))+" ")])])])])]}}])})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }